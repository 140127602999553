import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card h-100 border-0",
  "data-aos": "zoom-in"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card2 = _resolveComponent("Card2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::List Widget 5"),
    _createElementVNode("div", {
      class: _normalizeClass(["card border-0", _ctx.widgetClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Card2, {
          "card-classes": "h-100",
          title: "Total Customers ",
          icon: "fa-users text-danger",
          content: "63",
          color: "success",
          arrow: "Up-right",
          "footer-data": "+8.5%",
          "footer-text": "This Month"
        })
      ])
    ], 2 /* CLASS */),
    _createCommentVNode("end: List Widget 5")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}