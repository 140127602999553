import { createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_2 = { class: "card-title m-0" }
const _hoisted_3 = { class: "symbol symbol-45px w-45px bg-light me-4" }
const _hoisted_4 = {
  href: "#",
  class: "fontw fw-bold text-hover-primary text-gray-700 border-0 m-0 text-decoration-none"
}
const _hoisted_5 = { class: "card-body d-flex flex-column px-9 pt-3 pb-0" }
const _hoisted_6 = { class: "fs-2tx fw-bolder" }
const _hoisted_7 = { class: "d-flex align-items-center flex-wrap mb-4 mt-auto fs-6" }
const _hoisted_8 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_9 = { class: "badge bg-light text-gray-700 px-3 py-2 me-2" }
const _hoisted_10 = { class: "text-gray-400 fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", {
      class: _normalizeClass(["card h-100 shadow-lg border-0", _ctx.cardClasses]),
      style: {"border-radius":"10px"}
    }, [
      _createCommentVNode("border border-info"),
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_1, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode("begin::Icon"),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas fs-3x", _ctx.icon])
            }, null, 2 /* CLASS */)
          ]),
          _createCommentVNode("end::Icon"),
          _createCommentVNode("begin::Title"),
          _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _createCommentVNode("end::Title")
        ]),
        _createCommentVNode("end::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createCommentVNode("begin::Menu"),
        _createCommentVNode(" <button\r\n          type=\"button\"\r\n          class=\"btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3\"\r\n          data-kt-menu-trigger=\"click\"\r\n          data-kt-menu-placement=\"bottom-end\"\r\n          data-kt-menu-flip=\"top-end\"\r\n        >\r\n          <span class=\"svg-icon svg-icon-3 svg-icon-primary\">\r\n            <inline-svg src=\"media/icons/duotune/general/gen024.svg\" />\r\n          </span>\r\n        </button> "),
        _createCommentVNode("end::Menu"),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_5, [
        _createCommentVNode("begin::Heading"),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.content), 1 /* TEXT */),
        _createCommentVNode("end::Heading"),
        _createCommentVNode("begin::Stats"),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            class: _normalizeClass([`svg-icon-${_ctx.color}`, "svg-icon svg-icon-3 me-1"])
          }, [
            _createVNode(_component_inline_svg, {
              src: `media/icons/duotune/Navigation/${_ctx.arrow}.svg`
            }, null, 8 /* PROPS */, ["src"])
          ], 2 /* CLASS */),
          _createCommentVNode("begin::Number"),
          _createCommentVNode(" <div :class=\"`text-${color}`\" class=\"fw-bolder me-2\">\r\n          {{ footerData }}\r\n        </div> "),
          _createCommentVNode("end::Number"),
          _createCommentVNode("begin::Label"),
          _createCommentVNode(" <div class=\"fw-bold text-gray-400\">\r\n          {{ footerText }}\r\n        </div> "),
          _createCommentVNode("end::Label")
        ]),
        _createCommentVNode("end::Stats"),
        _createCommentVNode("begin::Indicator"),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.indicatorValue), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.indicatorLabel), 1 /* TEXT */)
        ]),
        _createCommentVNode("end::Indicator")
      ]),
      _createCommentVNode("end::Card body")
    ], 2 /* CLASS */),
    _createCommentVNode("end::Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}