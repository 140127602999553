import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_3 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_4 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_5 = { class: "row gy-5 gx-xl-8" }
const _hoisted_6 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_7 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_8 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_9 = { class: "row gy-5 g-xl-8" }
const _hoisted_10 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_11 = { class: "col-xxl-4 col-xl-4 col-md-6" }
const _hoisted_12 = { class: "col-xxl-4 col-xl-4 col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListWidget5 = _resolveComponent("ListWidget5")!
  const _component_ListTotalBinsonPlatform = _resolveComponent("ListTotalBinsonPlatform")!
  const _component_ListTotalCustomers = _resolveComponent("ListTotalCustomers")!
  const _component_ListTotalSigfoxDevices = _resolveComponent("ListTotalSigfoxDevices")!
  const _component_ListTotalLoraDevices = _resolveComponent("ListTotalLoraDevices")!
  const _component_ListTotalNbiotDevices = _resolveComponent("ListTotalNbiotDevices")!
  const _component_ListTotalMessages = _resolveComponent("ListTotalMessages")!
  const _component_ListTotalErrors = _resolveComponent("ListTotalErrors")!
  const _component_ListTotalPlatformUsers = _resolveComponent("ListTotalPlatformUsers")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Dashboard"),
    _createCommentVNode("Row 1 start"),
    _createCommentVNode(" <b-row>\r\n    <b-col>\r\n      <ListWidget5\r\n        widget-classes=\"card-xxl-stretch-35 mb-3 mb-xl-8\"\r\n      ></ListWidget5>\r\n      </b-col>\r\n    <b-col>\r\n      <ListTotalBinsonPlatform\r\n        widget-classes=\"card-xxl-stretch-35 mb-5 mb-xl-8\"\r\n      ></ListTotalBinsonPlatform>\r\n    </b-col>\r\n    <b-col>\r\n      <ListTotalCustomers\r\n        widget-classes=\"card-xxl-stretch-35 mb-5 mb-xl-8\"\r\n      ></ListTotalCustomers>\r\n    </b-col>\r\n  </b-row> "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ListWidget5, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ListTotalBinsonPlatform, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ListTotalCustomers, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ])
    ]),
    _createCommentVNode("Row 1 end "),
    _createCommentVNode("Row 2 start"),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ListTotalSigfoxDevices, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ListTotalLoraDevices, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ListTotalNbiotDevices, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ])
    ]),
    _createCommentVNode("Row 2 end"),
    _createCommentVNode("Row 3 start"),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_ListTotalMessages, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ListTotalErrors, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_ListTotalPlatformUsers, { "widget-classes": "card-xxl-stretch-35 mb-5 mb-xl-8" })
      ])
    ]),
    _createCommentVNode("Row 3 end "),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row g-5 gx-xxl-8" }, [
      _createElementVNode("div", { class: "col-xxl-4" }, [
        _createCommentVNode(" <MixedWidget5\r\n        widget-classes=\"card-xl-stretch mb-xl-8\"\r\n        chart-color=\"success\"\r\n        chart-height=\"150\"\r\n      ></MixedWidget5> ")
      ]),
      _createElementVNode("div", { class: "col-xxl-8" }, [
        _createCommentVNode(" <TableWidget5\r\n        widget-classes=\"card-xxl-stretch mb-5 mb-xxl-8\"\r\n      ></TableWidget5> ")
      ])
    ], -1 /* HOISTED */)),
    _createCommentVNode("end::Dashboard")
  ], 64 /* STABLE_FRAGMENT */))
}